<script lang="ts" setup>
const user = useSanctumUser<User>();
const localePath = useLocalePath();
const props = defineProps({
  description: {
    type: String,
    required: true,
  },
  mode: {
    type: String,
    default: "click",
  },
  sourceTool: {
    type: String,
    required: false,
    default: "",
  },
  reasonPurchase: {
    type: String,
    required: false,
    default: "",
  },
});

const { setReasonForPurchase } = usePurchase();
const redirectTo = () => {
  setReasonForPurchase(props.sourceTool, props.reasonPurchase);

  if (user) {
    return localePath("/checkout/pricing");
  }

  return localePath("register");
};
</script>

<template>
  <UPopover
    :mode="mode"
    :disabled="user?.is_subscribed"
    class="w-full"
    :ui="{
      rounded: 'rounded-lg',
    }"
    :popper="{ placement: 'top' }"
  >
    <slot />

    <template #panel>
      <div
        class="flex flex-col space-y-2 p-4 bg-gradient-to-b from-primary-500 to-primary-900 w-72"
      >
        <div class="flex items-start gap-2">
          <UIcon name="i-ph-crown-fill" class="w-10 h-10 text-yellow-400" />
          <div class="flex flex-col items-start">
            <p class="font-bold text-white">{{ $t("popover_pro.title") }}</p>
            <p class="text-sm text-left text-white">
              {{ description }}
            </p>
          </div>
        </div>

        <div class="text-center">
          <UButton
            :label="$t('pro_popover.button')"
            :to="redirectTo()"
            size="sm"
            variant="soft"
            block
          />
        </div>
      </div>
    </template>
  </UPopover>
</template>

<style></style>
